import { inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivateFn, Router } from '@angular/router';

import { firstValueFrom } from 'rxjs';

import { WebStorage } from '@app/shared/core';
import { AuthClaims } from '@app/shared/core/models/auth-claims.model';
import { Permission, PermissionManager, SUPER_ADMIN_ROLE } from '@app/shared/shared';
import { LocalStorageKeys } from '@app/shared/shared/constants';
import { TenantService } from '@app/shared/shared/services/tenant.service';
import { environment } from '@env/environment';

import { DaoFactory, Path, UserDAO } from '../../data';

export const RETURN_URL_KEY = 'returnUrl';

// eslint-disable-next-line func-style
export const authGuard: CanActivateFn = async () => {
	const afAuth = inject(AngularFireAuth);
	const router = inject(Router);
	const tenantService = inject(TenantService);
	const daoFactory = inject(DaoFactory);

	// Broke when using afAuth.currentUser
	const user = await firstValueFrom(afAuth.user);
	if (!user) {
		return router.parseUrl(`/login?${RETURN_URL_KEY}=${window.location.pathname}`);
	}

	let claims = (await user.getIdTokenResult()).claims as AuthClaims;
	const firestoreUser = (await firstValueFrom(daoFactory.build(UserDAO, Path.user(user.uid)))).snapshot;

	if (firestoreUser.role !== claims.role) {
		claims = (await user.getIdTokenResult(true)).claims as AuthClaims;
	}

	const { role, tenantId } = claims;
	setTenant(role, tenantId);
	await setPermissions(role, tenantService);
	return true;
};

function setTenant(role: string, tenantId: string | undefined) {
	if (role === SUPER_ADMIN_ROLE) {
		const storedTenant = WebStorage.getItem(LocalStorageKeys.TenantId);
		if (!storedTenant) {
			WebStorage.setItem(LocalStorageKeys.TenantId, environment.variables.defaultSuperAdminTenantId);
		}
	} else {
		if (!tenantId) {
			throw new Error('User in unmanageable state. Please contact admin.');
		}
		WebStorage.setItem(LocalStorageKeys.TenantId, tenantId);
	}
}

async function setPermissions(role: string, tenantService: TenantService) {
	if (role === SUPER_ADMIN_ROLE) {
		PermissionManager.setPermissions({
			analyticsSheets: [],
			permissions: Object.values(Permission),
			name: SUPER_ADMIN_ROLE,
		});
	} else {
		const roleDao = await firstValueFrom(tenantService.getRole(role));
		PermissionManager.setPermissions(roleDao.snapshot);
	}
}
